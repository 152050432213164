<template>
  <ep-layout @tabClicked="changeSharing" @open-create-panel="Create()" class="sharings">
    <!-- eslint-disable-next-line -->
    <div
      v-if="userShares.length > 0 && userShares[sharingIndex] && !(isCreateShare) && !(isEditShare)"
    >
      <el-toolbar class="mb-4" :sticky="true">
        <h1 class="el-main-title">{{ userShares[sharingIndex].name }}</h1>
        <el-toolbar-spacer />

        <el-rectangle-button-container v-if="selected.length  > 0">
          <el-rectangle-button-container :grey="true">
            <el-rectangle-button @click.native="showModal('deleteSelected')">
              <span class="icon-supprime"></span>
              {{ $t('sharing.toolbar.button.delete.all')}}
            </el-rectangle-button>
          </el-rectangle-button-container>
        </el-rectangle-button-container>

        <!-- <el-rectangle-button v-else-if="selected.length > 0" @click.native="deleteSelected">
          <span class="icon-supprime"></span>
          {{ $t('sharing.toolbar.button.delete')}}
        </el-rectangle-button>-->
      </el-toolbar>
      <el-table v-if="myshare.length > 0">
        <el-table-header>
          <el-table-row>
            <el-table-head data-type="input">
              <input
                class="el-checkbox"
                @click.stop
                type="checkbox"
                v-model="selectAll"
                @click="select"
              />
            </el-table-head>
            <el-table-head
              :rows="myshare[sharingIndex].sharings"
              :col="'startingDate'"
              :key="'startingDate'"
              :main="true"
            >{{ $t('sharing.table.head.startingDate') }}</el-table-head>
            <el-table-head
              :rows="myshare[sharingIndex].sharings"
              :col="'endingDate'"
              :key="'endingDate'"
              :main="true"
            >{{ $t('sharing.table.head.endingDate') }}</el-table-head>
            <el-table-head
              :rows="myshare[sharingIndex].sharings"
              :col="'nbDocuments'"
              :key="'nbDocuments'"
            >{{ $t('sharing.table.head.nbDocuments') }}</el-table-head>
            <el-table-head
              :rows="myshare[sharingIndex].sharings"
              :col="'consulted'"
              :key="'consulted'"
            >{{ $t('sharing.table.head.consulted') }}</el-table-head>
          </el-table-row>
        </el-table-header>
        <el-table-body>
          <el-table-row
            v-for="(item, index) in myshare[sharingIndex].sharings"
            :key="index"
            @click="goTo(item)"
          >
            <el-table-cell @click.native.stop data-type="input">
              <input
                class="el-checkbox"
                @click.stop="dome(item)"
                :value="item.id"
                type="checkbox"
                v-model="selected"
              />
            </el-table-cell>
            <el-table-cell
              :main="true"
            >{{ item.startingDate.split(' ')[0].split('-').reverse().join('/') }}</el-table-cell>
            <el-table-cell
              :main="true"
            >{{ item.endingDate.split(' ')[0].split('-').reverse().join('/') }}</el-table-cell>
            <el-table-cell>{{ item.nbDocuments }}</el-table-cell>
            <el-table-cell>
              <template v-if="item.consulted === '0'">
                {{ $t('sharing.table.cell.consulted.no') }}
              </template>
              <template v-else>{{ $t('sharing.table.cell.consulted.yes') }}</template>
            </el-table-cell>
            <el-table-cell data-type="button">
              <button
                class="el-rectangle-button"
                @click.stop="showModal('deleteItem', item)"
              >{{$t('sharing.create.button.delete')}}</button>
            </el-table-cell>
          </el-table-row>
        </el-table-body>
      </el-table>
    </div>
    <div
      v-else-if="!userShares.length && !sharingsLoading && !isEditShare && !isCreateShare"
      class="el-subtitle"
    >{{$t('sharing.display.data')}}</div>

    <!--modal Delete  -->
    <div class="el-dialog" v-show='modalVisible'>
      <div class="el-dialog__container">
        <div class="el-panel">
          <template v-if="selected.length>1">
            <h2 className="el-title">{{$t('sharing.deletetion.multiple.confirmation')}}</h2>
          </template>
          <template v-else>
            <h2>{{ $t('sharing.deletetion.single.confirmation') }}</h2>
          </template>
          <form v-on:submit.prevent="deleteData">
          <div class="ep-button--panel-middle">
            <button
              class="el-button el-button--inverted"
              @click.prevent="showModal"
            >{{ $t('sharing.create.button.cancel') }}</button>
            <button
              class="el-button e2e-validdeleteSharing"
              type="submit"
            >{{ $t('sharing.create.button.delete') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>


  </ep-layout>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import EpLayout from './Layout.vue';

export default {

  name: 'DocumentsSharing',


  props: {

    updateMe: {
      type: Boolean,
      default: false,
      required: false,
    },
  },


  components: {
    //  EpCreateSharingDialog,
    EpLayout,
  },


  data() {
    return {
      sharingIndex: 0,
      obj: {},
      myshare: [],
      isCreateShare: false,
      isEditShare: false,
      isDialogOpen: false,
      isEditDialogOpen: false,
      isDeleteDialogOpen: false,
      sharingsLoading: true,
      sharings: null,
      sharing: {
        recipientName: '',
        recipientEmail: '',
        duration: 7,
        fileIds: [],
      },
      selected: [],
      selectAll: false,
      modalVisible: false,
      deleteFunction: '',
      dataDeleted: undefined,
    };
  },


  computed: {
    ...mapState({
      sideMenu: state => state.application.sideMenu,
    }),
    ...mapGetters({
      userShares: 'sharings/userShares',

      usageDatas: 'user/usageDatas',
    }),
    // date() {
    //   if (this.isEditDialogOpen) {
    //     const d = this.obj.startingDate.split(' ');
    //     return d[0];
    //   }
    //   return null;
    // },
    // time() {
    //   if (this.isEditDialogOpen) {
    //     const d = this.obj.startingDate.split(' ');
    //     const l = d[1].split(':');
    //     return `${l[0]}:${l[1]}`;
    //   }
    //   return null;
    // },
  },


  methods: {
    ...mapActions({
      getSharings: 'sharings/all',
      createSharing: 'sharings/create',
      update: 'sharings/update',
      delete: 'sharings/delete',
      deleteMultiple: 'sharings/deleteMultiple',
      getUsageDatas: 'user/getUsageDatas',
    }),
    Edit() {
      this.isEditShare = true;
    },
    Create() {
      this.$router.push({
        name: 'sharings.create',
      });
    },

    updateIsOpen(isOpen) {
      this.isDialogOpen = isOpen;
    },
    dome(item) {
      this.obj = item;
    },

    openEditDialog() {
      this.isEditDialogOpen = true;
    },

    closeEditDialog() {
      this.isEditDialogOpen = false;
    },

    goTo(item) {
      this.$router.push({
        name: 'sharings.show',
        params: { sharingIndex: parseInt(this.sharingIndex, 10), sharingId: item.id },
      });
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        Object.values(this.userShares[this.sharingIndex].sharings).forEach(
          (value) => {
            this.selected.push(value.id);
          },
        );
      }
    },
    deleteItem(item) {
      this.delete(item.id).then(() => {
        this.getSharings().then(() => {
          this.getUsageDatas();
          // console.log('hi');
          this.sharings = this.userShares[this.sharingIndex];
          this.myshare = Array.from(this.userShares);
        });
      });
    },
    deleteSelected() {
      const promises = [];
      for (let i = 0; i < this.selected.length; i += 1) {
        const item = this.selected[i];

        promises.push(this.delete(item));
      }
      this.deleteMultiple(promises)
        .then(() => {
          this.getSharings().then(() => {
            this.getUsageDatas();
            this.selected = [];
            this.$store.dispatch('application/openSnackbar', {
              message: this.$i18n.t('documents.message.folder.delete.success'),
              time: 10,
              type: 'success',
              centered: true,
            });
          });
        })
        .catch(() => {
          this.$store.dispatch('application/openSnackbar', {
            message: this.$i18n.t('documents.message.folder.delete.fail'),
            time: 10,
            type: 'error',
            centered: true,
          });
        });
      this.selected = [];
      this.closeDeleteDialog();
    },
    changeSharing(value) {
      this.sharingIndex = value;
    },
    showModal(nameFunction, data) {
      this.modalVisible = !this.modalVisible;

      if (data !== undefined) {
        this.dataDeleted = data;
      }
      if (nameFunction !== undefined) {
        this.deleteFunction = nameFunction;
      } else {
        this.deleteFunction = '';
      }
    },

    deleteData() {
      if (this.deleteFunction !== '') {
        this[this.deleteFunction](this.dataDeleted);
        this.showModal();
        this.deleteFunction = '';
        this.dataDeleted = undefined;
      } else {
        this.showModal();
        this.deleteFunction = '';
        this.dataDeleted = undefined;
      }
    },
  },

  mounted() {
    if (!this.userShares.length) {
      this.getSharings().then(() => {
        this.sharings = this.userShares[this.sharingIndex];
        this.sharingsLoading = false;
        this.myshare = Array.from(this.userShares);
      });
    } else {
      this.myshare = Array.from(this.userShares);
      this.sharingsLoading = false;
    }
    if (this.updateMe === true) {
      this.getUsageDatas();
    }
  },

  watch: {
    sharingIndex(value) {
      this.selected = [];
      if (value !== null) {
        this.sharings = this.userShares[value];
      }
    },
    userShares() {
      this.sharings = this.userShares[this.sharingIndex];
      this.myshare = Array.from(this.userShares);
    },
  },
};
</script>
