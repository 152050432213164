<template>
  <ep-layout class="el-body">
    <div class="el-panel el-panel--aside-menu">
      <!-- aside menu -->
      <el-aside-menu :isOpen="isSideMenuOpened">
        <!-- top part without scroll -->
        <el-aside-menu-sticky>
          <el-aside-menu-title>
            <span class="icon-share"></span>
            <span>{{ $t('sharing.title') }}</span>
          </el-aside-menu-title>
          <button class="el-button el-button--full-width ep-button__focus" @click="openCreatePanel">
            <span class="icon-add"></span>
            {{ $t('sharing.create')}}
          </button>
        </el-aside-menu-sticky>
        <el-aside-menu-label>{{ $t('sharing.asideMenu.label') }}</el-aside-menu-label>
        <el-aside-menu-tab
          v-for="(item, index) in userShares"
          :key="'sharing_' + index"
          :class="{ 'active': selectedTab === index }"
          @click.native="goToSharing(index)"
        ><span class="icon-espace_perso"></span>{{ item.name }}</el-aside-menu-tab>
      </el-aside-menu>
      <slot></slot>
    </div>
  </ep-layout>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import EpLayout from '@/layouts/Default.vue';

export default {

  name: 'SharingsLayout',


  computed: {
    ...mapState({
      isSideMenuOpened: state => state.application.sideMenu.opened,
    }),
    ...mapGetters({
      userShares: 'sharings/userShares',
    }),
  },


  data() {
    return {
      selectedTab: 0,
    };
  },


  components: {
    EpLayout,
  },


  methods: {
    ...mapActions({
      fetchSharings: 'sharings/all',
    }),

    openCreatePanel() {
      this.$emit('open-create-panel');
    },
    goToSharing(sharingIndex) {
      this.selectedTab = sharingIndex;
      this.$emit('tabClicked', sharingIndex);
    },
  },

  created() {
    if (!this.userShares.length) {
      this.fetchSharings().then(() => {
        if (this.$route.params.sharingIndex) {
          this.goToSharing(this.$route.params.sharingIndex);
        }
      });
    } else if (this.$route.params.sharingIndex) {
      this.goToSharing(this.$route.params.sharingIndex);
    }
  },
  watch: {
    $route(to) {
      if (
        to.name === 'sharings.index'
        && Object.getOwnPropertyNames(to.params).length === 0
      ) {
        if (!this.userShares.length) {
          this.fetchSharings().then(() => {
            this.goToSharing(0);
          });
        }
      }
    },
    userShares() {
      if (!this.userShares[this.selectedTab]) {
        this.goToSharing(this.selectedTab - 1);
      }
    },
  },
};
</script>
